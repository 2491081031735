/**
 * Parses a JSON object from a script tag and
 * embeds a web shop on the page
 */

import { iframeResizer } from 'iframe-resizer'
import { WebShopTheme } from '../WebShopTheme'

type EmbedOptionsBase = {
  id: string
  theme?: WebShopTheme
}

type EmbedOptionsButton = EmbedOptionsBase & {
  type: 'button'
  isbn: string
  details?: string[]
}

type EmbedOptionsBundle = EmbedOptionsBase & {
  type: 'bundle'
}

const sortAttributes = [
  'priority:desc',
  'available_date:desc',
  'available_date:asc',
  'title:asc',
  'title:desc',
  'price:asc',
  'price:desc',
] as const

type EmbedOptionsShop = EmbedOptionsBase & {
  sortBy?: (typeof sortAttributes)[number]
}

type EmbedOptions = EmbedOptionsButton | EmbedOptionsShop | EmbedOptionsBundle

const swedishStrings = {
  errors: {
    invalidJson:
      'Butiken kunde inte läsas in. Kontrollera att inbäddningskoden är korrekt.',
    invalidId:
      'Butiken kunde inte läsas in. Kontrollera att id:t endast innehåller siffror.',
    invalidIsbn:
      'Butiken kunde inte läsas in. Kontrollera att ISBN-numret endast innehåller siffror.',
    invalidSortBy:
      'Butiken kunde inte läsas in. Kontrollera att sorteringen är korrekt.',
  },
}

const englishStrings = {
  errors: {
    invalidJson:
      'The webshop could not be loaded. Check that the embed code is correct.',
    invalidId:
      'The webshop could not be loaded. Check that the id only contains numbers.',
    invalidIsbn:
      'The webshop could not be loaded. Check that the ISBN only contains numbers.',
    invalidSortBy:
      'The webshop could not be loaded. Check that the sorting is correct.',
  },
}

const lang = window.Cypress != null ? 'sv' : navigator.language
const strings = lang.startsWith('sv') ? swedishStrings : englishStrings

function embedWebShop(): void {
  // Find the script tag that loaded this script
  const thisScriptTag = document.currentScript as HTMLScriptElement

  if (
    thisScriptTag == null ||
    thisScriptTag.innerHTML == null ||
    thisScriptTag.parentElement == null
  ) {
    return
  }

  const messageElement = document.createElement('p')
  messageElement.style.color = 'rgb(204, 0, 0)'

  function showErrorMessage(message: string): void {
    messageElement.innerHTML = message
    thisScriptTag.parentElement?.append(messageElement)
  }

  let parsedJson: EmbedOptions | undefined

  try {
    parsedJson = JSON.parse(thisScriptTag.innerHTML)
  } catch (err) {
    showErrorMessage(strings.errors.invalidJson)
  }

  if (parsedJson == null) {
    showErrorMessage(strings.errors.invalidJson)
    return
  }

  if (parsedJson.id == null || parsedJson.id.match(/^\d+$/) == null) {
    showErrorMessage(strings.errors.invalidId)
    return
  }

  const iframe = document.createElement('iframe')

  // Find index of current script tag to generate a unique id
  const index = Array.prototype.indexOf.call(
    document.getElementsByTagName('script'),
    thisScriptTag
  )

  iframe.id = `iframe-resizer-${index}`
  iframe.style.width = '100%'
  iframe.style.border = 'none'
  iframe.title = 'Webshop'

  const embedUrl = new URL(
    // Basing the URL on the source of the script tag ensures
    // that the domain is correct
    thisScriptTag.src
  )

  // Pass url to the embedding page
  embedUrl.searchParams.append('embedOrigin', window.location.href)

  // Set a good enough min height to avoid flickering
  const minHeight = matchMedia('(min-width: 480px)').matches ? 87 : 146

  // Determine the embed type
  if (isButtonEmbed(parsedJson)) {
    // The embed type is a button
    embedUrl.searchParams.append('embedType', 'button')

    if (parsedJson.isbn == null) {
      showErrorMessage(strings.errors.invalidIsbn)
      return
    }

    // Remove all non-digit characters in the ISBN
    const isbn = parsedJson.isbn.replace(/\D/g, '')

    iframe.style.height = `${minHeight}px`
    embedUrl.pathname = `/webshop/${parsedJson.id}/${isbn}/embed`

    if (parsedJson.theme != null) {
      embedUrl.searchParams.append('theme', JSON.stringify(parsedJson.theme))
    }

    if (parsedJson.details != null) {
      parsedJson.details.forEach((detail) => {
        embedUrl.searchParams.append('details', detail)
      })
    }
  } else if (isBundleEmbed(parsedJson)) {
    // The embed type is a bundle, i.e. buy all products in the shop with one click
    embedUrl.searchParams.append('embedType', 'bundle')

    iframe.style.height = `${minHeight}px`
    embedUrl.pathname = `/webshop/${parsedJson.id}/bundle`

    if (parsedJson.theme != null) {
      embedUrl.searchParams.append('theme', JSON.stringify(parsedJson.theme))
    }
  } else {
    // The embed type is a shop
    embedUrl.searchParams.append('embedType', 'embed')

    if (parsedJson.sortBy != null) {
      if (sortAttributes.includes(parsedJson.sortBy)) {
        embedUrl.searchParams.append('sort', parsedJson.sortBy)
      }
    }

    embedUrl.pathname = `/webshop/${parsedJson.id}`
  }

  iframe.src = embedUrl.toString()

  // Insert iframe after script tag
  thisScriptTag.insertAdjacentElement('afterend', iframe)

  iframeResizer(
    {
      heightCalculationMethod: 'taggedElement',
      minHeight,
      checkOrigin: false,
    },
    iframe
  )
}

function isButtonEmbed(options: EmbedOptions): options is EmbedOptionsButton {
  return (options as EmbedOptionsButton).type === 'button'
}

embedWebShop()
function isBundleEmbed(options: EmbedOptions): options is EmbedOptionsBundle {
  return (options as EmbedOptionsBundle).type === 'bundle'
}
